import React from "react";

import { BSBtn, BSCard } from "traec-react/utils/bootstrap";
import { ProjectPermission } from "../utils/permissions";

import useApi, { doFetch } from "storybook-dashboard/utils/fetching";
import { Modal, useModal } from "storybook-dashboard/components/modal";
import DataTable from "react-data-table-component";
import Im from "immutable";
import { ActionIcon } from "./utils";
import { formEventToData } from "./utils";
import { useProjectPermissions } from "../utils/hooks";
import { Hide } from "../../utils";

const createOrUpdateDiscipline = (e, { item, hideModal, projectId, trigger }) => {
  let data = formEventToData(e);

  if (!data.approver) {
    data.approver = null;
  }

  console.log("createOrUpdateDiscipline", e, data, item?.toJS());

  let url = `/api/project/${projectId}/discipline/` + (item ? `${item?.get("uid")}/` : "");
  let method = item ? "PATCH" : "POST";
  doFetch(url, method, data).then(() => {
    if (trigger) trigger();
    if (hideModal) hideModal();
  });
};

const deleteDiscipline = ({ item, projectId, trigger }) => {
  let disciplineId = item.get("uid");
  console.log("Deleting discipline", item?.toJS());

  doFetch(`/api/project/${projectId}/discipline/${disciplineId}/`, "DELETE").then(() => {
    if (trigger) trigger();
  });
};

function CreateOrEditNewDisciplineModal(props) {
  let { item, authgroups, disciplines } = props;
  return (
    <Modal title="Add a Supplier">
      <form onSubmit={(e) => createOrUpdateDiscipline(e, props)}>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="dName">Name</label>
              <input
                className="form-control form-control-sm"
                name="name"
                id="dName"
                defaultValue={item?.get("name") || ""}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <label htmlFor="dAuth">Auth</label>
              <select
                name="auth"
                className="form-control form-control-sm"
                defaultValue={item?.getIn(["auth", "uid"]) || ""}
              >
                {(authgroups || Im.List()).unshift(Im.Map({ uid: "", name: "" })).map((i, k) => (
                  <option key={k} value={i.get("uid")}>
                    {i.get("name")}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <label htmlFor="dApprover">Approver</label>
              <select
                name="approver"
                className="form-control form-control-sm"
                defaultValue={item?.getIn(["approver", "uid"]) || ""}
              >
                {(disciplines || Im.List()).unshift(Im.Map({ uid: "", name: "" })).map((i, k) => (
                  <option key={k} value={i.get("uid")}>
                    {i.get("name")}
                  </option>
                ))}
              </select>{" "}
            </div>
          </div>
        </div>
        <button className="btn btn-sm btn-primary" type="submit">
          Submit
        </button>
      </form>
    </Modal>
  );
}

const getColumns = (props) => {
  let { setModal, isAdmin } = props;
  return [
    {
      name: "Name",
      sortable: true,
      selector: (item) => item.get("name"),
      format: (item) => (
        <span style={{ marginLeft: `${item.getIn(["_ancestry", "level"]) - 1}rem` }}>{item.get("name")}</span>
      ),
      width: "30%",
    },
    {
      name: "Approver",
      selector: (item) => item.getIn(["approver", "name"]),
      format: (item) => item.getIn(["approver", "name"]),
      width: "30%",
    },
    {
      name: "AuthGroup",
      sortable: true,
      selector: (item) => item.getIn(["auth", "name"]),
      format: (item) => item.getIn(["auth", "name"]),
      width: "30%",
    },
    {
      name: "",
      right: true,
      selector: (item) => "",
      format: (item) => (
        <Hide when={!isAdmin}>
          <ActionIcon
            iconName="pencil"
            addClass="mr-2"
            onClick={() => setModal(<CreateOrEditNewDisciplineModal {...props} item={item} />)}
          />
          <ActionIcon iconName="trashcan" onClick={() => deleteDiscipline({ ...props, item })} />
        </Hide>
      ),
      width: "10%",
    },
  ];
};

const getAncestry = (discipline, disciplinesById) => {
  if (!discipline) return Im.Map({ path: Im.List(), level: 0 });
  let approverId = discipline.get("approver");
  let approver = approverId ? disciplinesById.get(approverId) : null;

  let details = getAncestry(approver, disciplinesById);

  return Im.Map({
    path: details.get("path").push(discipline.get("uid")),
    level: details.get("level") + 1,
  });
};

export default function DisciplineList({ projectId }) {
  let { setModal, hideModal } = useModal();
  let { isAdmin } = useProjectPermissions();
  let { data: disciplines, trigger } = useApi("/api/project/{projectId}/discipline/", { projectId });
  let { data: authgroups } = useApi("/api/project/{projectId}/authgroup/", { projectId });

  // Nest the approver and any disciplines that are approved
  let disciplinesById = disciplines?.reduce((a, i) => a.set(i.get("uid"), i), Im.Map());
  let _disciplines = disciplines
    ?.map(
      (i) =>
        i //
          .set("approver", disciplinesById.get(i.get("approver"))) // Set the full approver object
          .set("_ancestry", getAncestry(i, disciplinesById)) // Get the ancestry
    )
    ?.sortBy((i) => i.getIn(["_ancestry", "path"]));

  console.log("DisciplineList", _disciplines?.toJS());

  let _props = { disciplines, authgroups, setModal, hideModal, projectId, trigger, isAdmin };

  return (
    <div className="row">
      <BSCard
        widthOffset="col-sm-12"
        title="Project Suppliers"
        button={
          <ProjectPermission projectId={projectId} requiresAdmin={true}>
            <BSBtn
              onClick={() => setModal(<CreateOrEditNewDisciplineModal {..._props} projectId={projectId} />)}
              text="Add a Supplier"
            />
          </ProjectPermission>
        }
        body={<DataTable columns={getColumns(_props)} data={_disciplines?.toArray()} striped={true} dense={true} />}
      />
    </div>
  );
}
