import React, { useState } from "react";
import Moment from "moment";
import Traec from "traec";
import BaseFormConnected from "traec-react/utils/form";
import { BSBtnDropdown } from "traec-react/utils/bootstrap";

import { ProjectPermission } from "../utils/permissions";
import { useFullIds, useProjectPermissions } from "../utils/hooks";

export const counter = { row: 0 };

const patchFields = {
  startDate: { label: "Start of this period", value: "", inputType: "date", class: "col" },
  endDate: { label: "Start of next period", value: "", inputType: "date", class: "col", endRow: true },
};

const ReportingPeriodRow = (props) => {
  const { reportingPeriod } = props;
  const { projectId } = useFullIds();
  const { isAdmin } = useProjectPermissions();
  let reportingPeriodId = reportingPeriod.get("uid");
  let fetch = new Traec.Fetch("project_reporting_periods", "patch", { projectId, reportingPeriodId });

  const [showForm, setShowForm] = useState(false);
  const [formParams, setFormParams] = useState(fetch.params);

  const dropDownLinks = () => {
    if (!isAdmin) {
      return [];
    }
    let links = [{ name: "Edit", onClick: editItem }];

    if (!reportingPeriod.get("num_commits")) {
      links = links.concat([{ name: "Delete", onClick: deleteItem }]);
    }

    return links;
  };

  const editItem = (e) => {
    e.preventDefault();
    let reportingPeriodId = reportingPeriod.get("uid");
    let fetch = new Traec.Fetch("project_reporting_periods", "patch", { projectId, reportingPeriodId });
    //
    fetch.updateFetchParams({
      preFetchHook: (data) => {
        return data;
      },
    });
    setFormParams(fetch.params);
    // Set the initial fields to the start and end date
    patchFields.startDate.value = reportingPeriod.getInPath("startDate");
    patchFields.endDate.value = reportingPeriod.getInPath("endDate");
    // Toggle form vis
    fetch.toggleForm();
  };

  const deleteItem = (e) => {
    e.preventDefault();
    let reportingPeriodId = reportingPeriod.get("uid");
    let fetch = new Traec.Fetch("project_reporting_periods", "delete", { projectId, reportingPeriodId });
    fetch.updateFetchParams({
      postSuccessHook: (e) => {
        location.reload();
      },
    });
    fetch.dispatch();
  };

  const dateString = (key) => {
    return Moment(reportingPeriod.get(key)).format("Do MMM YY") || "undefined";
  };

  const reportingperiod_string = () => {
    let periodString = reportingPeriod
      ? `${Moment(reportingPeriod.get("startDate")).format("Do MMM YY")} to ${Moment(reportingPeriod.get("endDate"))
          .add(-1, "days")
          .format("Do MMM YY")}`
      : null;
    if (!periodString) {
      periodString = " ";
    }
    return periodString;
  };

  let rowNum = counter.row++;
  let styleObj = { backgroundColor: (rowNum + 1) % 2 ? "#ddd" : "" };
  return (
    <>
      <div className="row" style={styleObj}>
        <div className="col-sm-2">{dateString("startDate")}</div>
        <div className="col-sm-2">
          {Moment(reportingPeriod.get("endDate")).add(-1, "days").format("Do MMM YY") || "undefined"}
        </div>
        <div className="col-sm-2 text-center">{reportingPeriod.get("num_commits")}</div>
        <div className="col-sm-5">{reportingPeriod.get("uid").substring(0, 8)}</div>
        <div className="col-sm-1">
          <BSBtnDropdown links={dropDownLinks()} />
        </div>
      </div>
      <div className="row">
        <BaseFormConnected params={formParams} fields={patchFields} />
      </div>
    </>
  );
};

export default (props) => {
  const { projectId } = useFullIds();
  return (
    <ProjectPermission projectId={projectId} requiresAdmin={true}>
      <ReportingPeriodRow {...props} />
    </ProjectPermission>
  );
};
