import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { ErrorBoundary } from "traec-react/errors";
import { setNavBarItems } from "traec-react/navBar";

import CompanyInvites from "./companyInvite";
import Notifications from "../notifications";
import ProjectInvites from "./projectInvite";
import UserNotificationTree from "./tree";
import { styledTab, activeStyledTab } from "storybook-dashboard/utils";

let navbarLinks = [{ label: " ", octicon: "home", to: "/" }];

function UserProfile(props) {
  let { user, dispatch, companyItems, projectItems } = props;
  const [activeTab, setActiveTab] = useState("company");

  const userFirstName = user?.get("first_name") || "";

  const inviteTabs = [
    {
      id: "company",
      name: "Outstanding company invites",
      number: companyItems?.size || 0,
    },
    {
      id: "project",
      name: "Outstanding project invites",
      number: projectItems?.size || 0,
    },
  ];

  const handleTabSwitch = (tabId) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    dispatch(setNavBarItems(navbarLinks));
  }, [dispatch]);

  return (
    <div className="container">
      <br />
      <div className="my-5 d-flex justify-content-between">
        <div>
          <h2 className="font-weight-bold ">Welcome to the Sustainability Tool {userFirstName}</h2>
          <p className="text-muted">
            Use this page to review outstanding invitations and navigate between your dashboards and reports.
          </p>
        </div>
        {/* <img
          style={{ maxHeight: "150px" }}
          src="https://raw.githubusercontent.com/ActionSustainability/static-content/7c6a472ade628c89be97460915c9a4436943e89e/mainTool/undraw_welcoming.svg"
        /> */}
      </div>
      <div className="">
        <div className="shadow rounded p-4 mb-5">
          <Notifications />
        </div>
      </div>
      <div className="">
        <h4 className="font-weight-bold">Invitations</h4>
        <p className="text-muted">Use the tabs below to approve and reject outstanding invitations.</p>
        <div className="shadow rounded p-4 mb-5">
          <div className="d-flex mb-4" style={{ cursor: "pointer" }}>
            {inviteTabs.map((tab) => {
              return (
                <a
                  key={tab.id}
                  className={`${styledTab} ${activeTab === tab.id ? `${activeStyledTab}` : ``}`}
                  onClick={() => handleTabSwitch(tab.id)}
                >
                  {tab.name} <span class="badge badge-primary">{tab.number}</span>
                </a>
              );
            })}
          </div>
          <ErrorBoundary>
            {activeTab === "company" && (
              <>
                <CompanyInvites />
                {/* The below div is included because the data for the projectItems is in redux and needs to be rendered into the UI to be accessible. */}
                <div style={{ display: "none" }}>
                  <ProjectInvites />
                </div>
              </>
            )}
            {activeTab === "project" && <ProjectInvites />}
          </ErrorBoundary>
        </div>
      </div>
      <ErrorBoundary>
        <UserNotificationTree user={user} />
      </ErrorBoundary>
    </div>
  );
}

UserProfile.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.getInPath(`auth.user`),
  userFirstName: state.getIn(["auth", "user", "first_name"]),
  companyItems: state.getInPath("entities.companyInvites.byId")?.filter((i) => i?.getInPath("company.name")),
  projectItems: state
    .getInPath("entities.projectInvites.byId")
    ?.filter((i) => i?.getInPath("project_discipline.project.name")),
});

export default connect(mapStateToProps)(UserProfile);
