import React from "react";
import Im from "immutable";

import LoginForm from "storybook-dashboard/auth/login";
import { DropDownItem } from "./dropdown";

import { useToken } from "storybook-dashboard/auth/token";

const UserLabel = ({ user, getUserItemLabel }) => {
  if (getUserItemLabel) return getUserItemLabel(user);
  let label = user
    ? ((user.get("first_name") || "").charAt(0) + (user.get("last_name") || "").charAt(0)).trim() ||
      user.get("username")
    : "User";
  return label;
};

const userDropDownItems = (props) => {
  let { user, signOut, include_myprofile = true, includeUser = false } = props;
  let menu = [];
  // Include a link to myprofile
  if (include_myprofile) {
    menu = menu.concat([{ label: "My Profile", to: "/accounts/profile", octicon: "home" }]);
  }

  if (includeUser) {
    menu = menu.concat([{ label: UserLabel(props), to: "/user/", octicon: "person" }]);
  }
  // Superuser-related menus
  if (user && user.get("is_tenant_superuser")) {
    menu = menu.length ? menu.concat([{ label: null }]) : menu;
    menu = menu.concat([{ label: "Tenacy admin", to: "/tenant/admin/", octicon: "gear" }]);
  }
  // Password change
  menu = menu.concat([
    { label: null },
    {
      label: "Change Password",
      to: "/accounts/password/change/",
      octicon: "key",
    },
    { label: null },
    { label: "Terms & Conditions", to: "/terms", octicon: "pencil" },
  ]);
  // Logout menu
  menu = menu.concat([{ label: null }, { label: "Logout", onClick: () => signOut(), octicon: "sign-out" }]);
  return Im.fromJS(menu);
};

export default function DropdownLogin(props) {
  let { signOut, token, decodeToken, isAuthenticated } = useToken();
  let { azureConfig, createText, userLabel = null } = props;

  let user = Im.fromJS(decodeToken(token));
  userLabel = userLabel || UserLabel({ user });

  if (!isAuthenticated) {
    return (
      <li className="dropdown order-1">
        <button
          type="button"
          id="dropdownMenu1"
          data-toggle="dropdown"
          className="btn btn-outline-secondary dropdown-toggle"
        >
          Login <span className="caret" />
        </button>
        <ul className="dropdown-menu dropdown-menu-right mt-1 mb-0 pb-0 dropdown-login-form">
          <li className="p-0 m-0">
            <LoginForm createText={createText} azureConfig={azureConfig} />
          </li>
        </ul>
      </li>
    );
  } else {
    return (
      <DropDownItem
        label={<span className="user-dropdown-header">{userLabel}</span>}
        items={userDropDownItems({ ...props, user, signOut })}
        extraDropdownClass={"dropdown-menu-right"}
      />
    );
  }
}
