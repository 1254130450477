import Im from "traec/immutable";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Traec from "traec";

import { ProjectPermission } from "AppSrc/project/utils/permissions";
import { Spinner } from "traec-react/utils/entities";
import IndicatorRow from "./indicatorRow";
import { getProjectProps, BreadCrumb } from "AppSrc/project/utils";
import { CreateIndicatorForm } from "./form";
import { ErrorBoundary } from "traec-react/errors";
import { Hider } from "storybook-dashboard/utils";
import { useFullIds } from "../utils/hooks";

function CreateIndicatorFormWrapper({ baseMetrics, refBaseMetrics, dispatch, rootMetrics, onClose }) {
  let { projectId, trackerId, commitId, isRootRef } = useFullIds();

  // Using rootMetrics rather than baseMetrics so that the adding indicator
  // options are not pulled from other users/projects
  let useBaseMetrics = (isRootRef ? rootMetrics : refBaseMetrics) || baseMetrics;

  return (
    <div className="row">
      <ErrorBoundary>
        <CreateIndicatorForm
          {...{ projectId, trackerId, commitId, dispatch }}
          baseMetrics={useBaseMetrics}
          toggleShowHandler={onClose}
          onSuccess={onClose}
        />
      </ErrorBoundary>
    </div>
  );
}

class ProjectIndicators extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchedEdges: false,
      fetchedIndicators: false,
      showNewForm: false,
      is_loading: true,
    };

    this.requiredFetches = [
      new Traec.Fetch("tracker_commit_indicator", "list"),
      new Traec.Fetch("tracker_node", "list"),
      new Traec.Fetch("tracker_commit_target", "list"),
    ];

    this.toggleForm = this.toggleForm.bind(this);
  }

  /**********************
     COMPONENT METHODS
     **********************/

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate() {
    Traec.fetchRequiredFor(this);
  }

  /**********************
     MENU OPERATIONS
     **********************/

  dropDownLinks() {
    let items = [
      { name: "Edit", onClick: this.editIndicator },
      { name: "Delete", onClick: this.deleteIndicator },
    ];
    return items;
  }

  toggleForm(e) {
    e.preventDefault();
    this.setState({ showNewForm: !this.state.showNewForm });
  }

  /**********************
     RENDER METHODS
     **********************/

  render_indicator_list() {
    let { commitIndicators, metricTargetMap, projectId, trackerId, commitId, baseMetrics, dispatch, isRootRef, cref } =
      this.props;
    if (!commitIndicators) {
      return null;
    }

    return commitIndicators
      .toList()
      .sortBy((i) => i.getInPath("resultBaseMetric.category"))
      .map((indicator, i) => {
        let metricId = indicator.getInPath("resultBaseMetric.uid");
        let indicatorTarget = indicator.set("metricTarget", metricTargetMap.get(metricId));
        return (
          <ErrorBoundary key={i}>
            <IndicatorRow
              indicator={indicatorTarget}
              projectId={projectId}
              trackerId={trackerId}
              commitId={commitId}
              baseMetrics={baseMetrics}
              dispatch={dispatch}
              isRootRef={isRootRef}
              refId={cref.get("uid")}
            />
          </ErrorBoundary>
        );
      });
  }

  static render_indicator_list_headers() {
    return (
      <div className="row">
        <div className="col-sm-4">
          <b>{"Name"}</b>
        </div>
        <div className="col-sm-2">
          <b>{"Sustainability Issue"}</b>
        </div>
        <div className="col-sm-4">
          <b>{"Function & Metrics"}</b>
        </div>
        <div className="col-sm-1">
          <b>Target</b> | <b>Warn</b>
        </div>
        <div className="col-sm-1">
          <b className="float-right">{"Admin"}</b>
        </div>
      </div>
    );
  }

  company_name() {
    let { company } = this.props;
    if (!company) {
      return "";
    }
    return <Link to={`/company/${company.get("uid")}`}>{company.get("name")}</Link>;
  }

  render() {
    let { company, project, projectId, cref, isRootRef, baseMetrics, refBaseMetrics, rootMetrics, dispatch } =
      this.props;

    if (!project || !baseMetrics) {
      return <Spinner title="Loading..." explanation="Loading Indicator List. It might take a moment" />;
    }

    return (
      <ProjectPermission projectId={projectId} requiresAdmin={true}>
        <h3>Indicators</h3>
        <BreadCrumb company={company} project={project} cref={cref} isRootRef={isRootRef} />

        <p>
          Indicators are operations that are done to the metrics. The output from an indicator is a new metric that is
          derived from the operation.
        </p>
        <button className="btn btn-sm btn-primary float-right" onClick={this.toggleForm}>
          Add a new indicator
        </button>
        <div style={{ clear: "both" }} />
        <Hider hide={!this.state.showNewForm}>
          <CreateIndicatorFormWrapper
            {...{ baseMetrics, refBaseMetrics, rootMetrics, dispatch }}
            onClose={() => this.setState({ showNewForm: false })}
          />
        </Hider>
        <br />
        <br />
        {ProjectIndicators.render_indicator_list_headers()}
        <hr />
        {this.render_indicator_list()}
      </ProjectPermission>
    );
  }
}

const getBaseMetricObject = (state, baseMetricId) => {
  if (Traec.Im.isImmutable(baseMetricId)) {
    return baseMetricId;
  }
  return state.getInPath(`entities.baseMetrics.byId.${baseMetricId}`);
};

const getCommitBaseMetrics = (state, commitId, commitNodes) => {
  if (!commitId || !commitNodes) {
    return Traec.Im.List();
  }
  let metricScoreIds = commitNodes
    .get("byPath")
    .toList()
    .filter((i) => i.get("type") == "metricscore")
    .map((i) => i.get("uid"));
  // Convert to a set for uniqueness
  metricScoreIds = Im.Set(metricScoreIds);
  // Return the baseMetric nested within the metric object
  return metricScoreIds.map((id) =>
    getBaseMetricObject(state, state.getInPath(`entities.metricScores.byId.${id}.metric`))
  );
};

const mapStateToProps = (state, ownProps) => {
  //const { _projectId, _refId } = ownProps.match.params;
  const { projectId, refId } = Traec.utils.getFullIds(state, ownProps.match.params);

  let { company, project, tracker, trackerId, cref, crefId, isRootRef } = getProjectProps(state, projectId, refId);

  // Get the edges so that we can get all baseMetrics from the treescore list
  let commitId = cref ? cref.getInPath("latest_commit.uid") : null;
  let commitEdges = commitId ? state.getInPath(`entities.commitEdges.byId.${commitId}`) : null;

  // NOTE: the fetchHandler for commitEdges already extracts and flattens the metricScore and baseMetric objects
  // so we can get what we want directly from `entties.baseMetrics.byId`
  let baseMetrics = state.getInPath(`entities.baseMetrics.byId`);
  let commitIndicators = state.getInPath(`entities.commitEdges.byId.${commitId}.indicators`);

  // Get the commitNodes
  let commitNodes = state.getInPath(`entities.commitNodes.${commitId}`);

  // Filter down the list of baseMetrics to only those that are available in this Ref
  let refBaseMetrics = Im.List();
  if (!isRootRef && commitId) {
    refBaseMetrics = getCommitBaseMetrics(state, commitId, commitNodes);
  }

  // Creating a new List prop with metrics for the project home directory. This is to solve a bug that navigating between
  // Reporting packages and the metric views would cause issues with what indicators are displayed in the
  // Numerator/Denominator selection field for metrics -- Jira: ASS-395
  let rootMetrics = getCommitBaseMetrics(state, commitId, commitNodes) || Im.List();

  // Get the targets pulled down
  let commitTargets = commitId ? state.getInPath(`entities.commitEdges.byId.${commitId}.metricTargets`) : Im.Map();
  commitTargets = commitTargets || Im.Map();
  let metricTargetMap = commitTargets.mapEntries(([k, v]) => [v.getInPath("metric.uid"), v]);

  // Set the props
  return {
    company,
    projectId,
    project,
    tracker,
    trackerId,
    cref,
    isRootRef,
    commitId,
    commitEdges,
    baseMetrics,
    refBaseMetrics,
    rootMetrics,
    commitIndicators,
    metricTargetMap,
  };
};

export default connect(mapStateToProps)(ProjectIndicators);
